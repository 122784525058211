.noItems {
  padding: 20px;
  color: var(--color-content-3);
  font-size: 16px;
  line-height: 28px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.content {
  display: flex;
  height: 100%;
}

.addItemArrow {
  margin-inline-start: 20px;
  margin-bottom: 30px;
}

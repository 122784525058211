.dateInput {
  z-index: 5;
  width: 100%;
  border: 0;
  outline: 0;
  cursor: pointer;

  font-size: 16px;
  color: var(--color-content-1);
  line-height: 25px;
  height: 25px;

  display: flex;
  flex-direction: column-reverse;
  margin: 10px 0 5px 0;
}

.dateModal {
  z-index: 20;
  margin-top: -20px;
  position: absolute;
  box-shadow:
    0 0 10px rgba(0, 0, 0, 0.1),
    0 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: var(--color-background);
  color: var(--color-content-1);
}

.background {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

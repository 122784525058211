.screenContainer {
  background-color: var(--color-background);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.screenContent {
  flex: 1;
  overflow-y: auto;
}

.horizontalPadding {
  padding-left: var(--screen-padding);
  padding-right: var(--screen-padding);
}

.bottomPadding {
  padding-bottom: var(--screen-padding);
}

.storyContent {
  width: 100%;
  height: 1400px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    29deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(24, 24, 203, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
}

.optionName {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 16px;
}

.icon {
  margin-inline-end: 20px;
  margin-top: 2px;
}

.iconFill {
  fill: var(--color-content-1);
}
.iconStroke {
  stroke: var(--color-content-1);
}

.languageRight {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.languageText {
  font-size: 16px;
  line-height: 22px;
  color: var(--color-content-3);
  margin-inline-end: 10px;
}

.signOut {
  padding: 10px 23px;
  display: flex;
  flex-direction: row;
}

.signOutText {
  padding-inline-start: 8px;
}

.title {
  color: var(--color-content-1);
  font-size: 20px;
  font-weight: 500;
  padding: 20px;
}

.updateAppScreen {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.title {
  color: var(--color-content-1);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 20px 0 20px 0;
}

.content {
  color: var(--color-content-2);
  font-size: 16px;
  text-align: center;
  margin: 0 10px;
}

.button {
  width: 180px;
  margin: 20px 30px 0 30px;
}

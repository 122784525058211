.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.title {
  color: var(--color-content-1);
  font-weight: 600;
  font-size: 24px;
  margin: 20px;
}

.subtitle {
  color: var(--color-content-2);
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin: 20px;
}

.button {
  margin: 20px;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;600;700;800&display=swap");

html,
body {
  overscroll-behavior: none;
  margin: 0;
  overflow-x: hidden;
}

:root {
  --font-family: Poppins, Assistant, Tajawal, Nunito Sans, serif;
  font-family: var(--font-family);
  caret-color: transparent; /* Hides blinking cursor */
  -webkit-user-select: none; /* Prevents selecting text */
  -webkit-tap-highlight-color: transparent; /* Prevents blue highlight when pressing */
}

:root {
  --font-size-button: 18px;
  --font-weight-button: 500;
  --screen-padding: 20px;
  --bottom-button-padding: 30px;
}

body {
  --color-primary: #8260ff;
  --color-primary-dark: #6f52d9;
  --color-primary-light: #b6a7fd;
  --color-primary-very-light: #efe5fd;

  --color-secondary: #72d7ff;
  --color-secondary-dark: #24baf4;
  --color-secondary-light: #a8e6ff;

  --color-cards: #ffffff;
  --color-bottom-menu-background: #ffffffb3; // 70
  --color-bottom-menu-icons: #a1a1a1;
  --color-fab-icon: #212121;
  --color-receipt-buttons: #ffffff;

  --color-content: #ffffff;
  --color-content-1: #212121;
  --color-content-2: #424242;
  --color-content-3: #a1a1a1;
  --color-content-4: #c4c4c4;
  --color-content-5: #e3e3e3;

  --color-button-1: #c4c4c4;
  --color-button-2: #e3e3e3;
  --color-button-3: #a1a1a1;

  --color-disabled-text: #7e7e7e;
  --color-disabled: #dddddd;
  --color-error: #cb0027;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-background: #ffffff;
  --color-extreme: #ffffff;

  --progress-bar-normal: var(--color-primary);
  --progress-bar-soon: #ffa800;
  --progress-bar-about-to-expire: var(--color-error);
  --progress-bar-expired: var(--color-disabled);
  --progress-bar-background: #efe5fd;

  --color-success: #29d06c;
  --color-success-dark: #5cda8e;
  --color-success-message: #d9ffe8;
  --color-success-text: var(--color-black);

  --color-google: #db4437;
  --color-google-active: #be3024;
  --color-facebook: #4267b2;
  --color-facebook-active: #325293;
  --color-apple: #2b2b2b;
  --color-apple-active: #161616;
}

// Used https://www.joshuamiron.com/percent-to-hex-converter
body.dark {
  --color-primary: #b897eb;
  --color-primary-dark: #ab89f0;
  --color-primary-light: #b897eb;
  --color-primary-very-light: #e7d6ff;

  --color-secondary: #72d7ff;
  --color-secondary-dark: #4ec6f5;
  --color-secondary-light: #a8e6ff;

  --color-cards: #ffffff0d; // 05
  --color-bottom-menu-background: #2e2e2e;
  --color-bottom-menu-icons: #ffffff99; // 60
  --color-fab-icon: #212121;
  --color-receipt-buttons: #ffffff0d; // 05

  --color-content: #2e2e2e;
  --color-content-1: #ffffffde; // 87
  --color-content-2: #ffffff99; // 60
  --color-content-3: #ffffff61; // 38
  --color-content-4: #ffffff61; // 38
  --color-content-5: #ffffff1f; // 12

  --color-button-1: #acacac;
  --color-button-2: #c3c3c3;
  --color-button-3: #898989;

  --color-disabled-text: var(--color-content-3);
  --color-disabled: var(--color-content-5);
  --color-error: #e86b74;
  --color-background: #121212;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-extreme: #000000;

  --progress-bar-normal: var(--color-primary);
  --progress-bar-soon: #ffcb63;
  --progress-bar-about-to-expire: var(--color-error);
  --progress-bar-expired: var(--color-disabled);
  --progress-bar-background: #332b3e;

  --color-success: #61d988;
  --color-success-dark: #29d06c;
  --color-success-message: #92e4b0;
  --color-success-text: var(--color-black);

  --color-google: #dd7773;
  --color-google-active: #ee5f59cc;
  --color-facebook: #66a6ea;
  --color-facebook-active: #3a94f4;
  --color-apple: #4d4d4d;
  --color-apple-active: #313131;
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 20px auto 20px;
  grid-template-rows: 50px;
  grid-gap: 10px;
  align-items: center;
}

.circles {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.circle {
  height: 9px;
  width: 9px;
  border-radius: 9px;
  margin: 3px;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notActive {
  background-color: var(--color-content-5);
}

.active {
  background-color: var(--color-primary);
}

.applicable {
  cursor: pointer;
  color: var(--color-content-2);
  stroke: var(--color-content-2);
}

.disabled {
  color: var(--color-content-3);
  stroke: var(--color-content-3);
}

.previous {
  justify-content: flex-start;
}

.next {
  justify-content: flex-end;
}

.chevronNext {
  margin-inline-start: 6px;
}

[dir="rtl"] .chevron {
  transform: scaleX(-1);
}

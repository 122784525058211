.container {
  height: 72px;
  margin: 10px 0;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: var(--color-cards);
}

.category {
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 760px) {
    width: 300px;
  }
}

.enabledCategory {
  cursor: pointer;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.circle {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin: 15px;
}

.categoryText {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: var(--color-content-2);
}

.deleteButton {
  height: 100%;
  border-start-end-radius: 10px;
  border-end-end-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 0;
  transition: width 250ms;
}

.deleteButtonShown {
  width: 49px;
}

.disabledDelete {
  background-color: var(--color-disabled);
  stroke: var(--color-content-3);
}

.enabledDelete {
  background-color: var(--color-primary);
  stroke: var(--color-extreme);
  cursor: pointer;
}

//.enabledDelete:hover {
//  background-color: var(--color-primary-light);
//}

.enabledDelete:active {
  background-color: var(--color-primary-dark);
}

.itemsCount {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-black);
  background-color: var(--color-primary-very-light);

  margin-inline-end: 20px;
  padding: 5px;
  min-width: 20px;
  border-radius: 5px;
  text-align: center;
}

.testButton {
  width: 1px;
  height: 1px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;

  @media (min-width: 760px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.categoriesList {
  flex: 1;
}

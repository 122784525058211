.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.illustration {
  text-align: center;
  padding: 20px;
}

.content {
  flex: 1;
}

.appInfo {
  color: var(--color-content-1);
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  padding: 10px 0;
}

.creditContent {
  margin-top: 20px;
  color: var(--color-content-1);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.creditTitle {
  font-weight: 500;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.versionNumber {
  text-align: center;
  color: var(--color-content-1);
  font-size: 12px;
  line-height: 24px;
}

.showButton {
  color: var(--color-content-1);
  font-weight: 500;
}

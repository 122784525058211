.screen {
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.email {
  font-size: 16px;
  line-height: 22px;
  color: var(--color-content-1);
  text-align: center;
  padding: 10px 10px 50px 10px;
}

.updatePasswordTitle {
  font-weight: 500;
  font-size: 16px;
  color: var(--color-content-1);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.button {
  padding: 15px 0;
}

.personIcon {
  stroke: var(--color-content-3);
}

.lockIcon {
  fill: var(--color-content-3);
}

.deleteAccount {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  cursor: pointer;
}

.deleteAccountText {
  padding-inline-start: 5px;
  color: var(--color-error);
  font-size: 12px;
}

.deleteAccountIcon {
  height: 12px;
  stroke: var(--color-error);
}

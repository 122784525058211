.checkbox {
  width: 20px;
  height: 20px;
  position: relative;
  border: 2px solid var(--color-primary);
  border-radius: 3px;
  cursor: pointer;

  transition: all 100ms ease-out;
}

.checkboxChecked {
  background-color: var(--color-primary);
}

.checkedV {
  position: absolute;
}

.lineCommon {
  position: absolute;
  bottom: 5px;
  height: 2px;
  background-color: var(--color-white);
  border-radius: 20px;
}

.smallLine {
  bottom: 0.4em;
  left: 0.12em;
  width: 7px;
  transform: rotate(45deg);
}

.bigLine {
  bottom: 0.6em;
  right: 0.01em;
  width: 15px;
  transform: rotate(-45deg);
}

.successScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 80%;
  color: var(--color-content-1);
  padding: 20px;
}

.imageContainer {
  padding: 20px 0;
}

.itemImage {
  max-height: 250px;
  width: 100%;
  border: 1px solid rgba(33, 33, 33, 0.05);
  border-radius: 10px;
}

.illustration {
  padding: 50px 0;
}

.itemName {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 10px;
}

.subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
}

.notificationDays {
  background-color: var(--color-success-message);
  color: var(--color-success-text);
  padding: 20px 30px;
  border-radius: 7px;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 40px;
}

.container {
  padding: 10px 0 20px 0;
  @media (min-width: 760px) {
    padding: 10px;
  }
}

.card {
  display: flex;
  flex-direction: row;
  background: var(--color-cards);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  cursor: pointer;

  height: 128px;
  @media (max-width: 360px) {
    height: 100px;
  }
  @media (min-width: 760px) {
    height: unset;
    flex-direction: column;
    width: 250px;
  }
}

.imageContainer {
  height: 128px;
  width: 128px;
  @media (max-width: 360px) {
    height: 100px;
    width: 100px;
  }
  @media (min-width: 760px) {
    height: 250px;
    width: 250px;
  }
}

.image {
  border-start-start-radius: 10px;
  border-end-start-radius: 10px;

  @media (min-width: 760px) {
    border-start-end-radius: 10px;
    border-end-start-radius: 0;
  }
}

.contentColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: start;
  padding: 7px 12px;
}

.cardHeader {
  line-height: 22px;
  width: 100%;
  display: table;
  table-layout: fixed;
}

.title {
  color: var(--color-content-1);
  font-size: 18px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 360px) {
    font-size: 16px;
  }
}

.subtitle {
  color: var(--color-content-2);
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 360px) {
    font-size: 14px;
  }
}

.bottomText {
  color: var(--color-content-1);
  font-size: 14px;
  font-weight: 500;
  @media (max-width: 360px) {
    font-size: 12px;
  }
}

.expired {
  color: var(--color-content-3);
}

.container {
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.tab {
  cursor: pointer;
  height: 36px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: var(--color-content-1);
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  // TODO smooth tabs transition
}

.selectedTab {
  color: var(--color-primary);
}

.border {
  height: 1px;
  margin-top: 4px;
  width: 100%;
  background-color: var(--color-content-4);
}

.selectedTabBorder {
  height: 3px;
  margin-top: 2px;
  background-color: var(--color-primary);
}

.tabContent {
  flex: 1;
  padding: var(--screen-padding);
  overflow-y: scroll;
}

.tabInnerContent {
  height: 100%;
}

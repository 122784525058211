.inputContainer {
  margin-top: 10px;
  margin-bottom: 35px;
  color: var(--color-content-3);
}

.inputContent {
  position: relative;
  display: flex;
  flex-direction: row;
}

.labelAndContent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.inputBorder {
  border-bottom: 1px solid var(--color-content-4);
}

.inputBorder:focus-within {
  border-bottom: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.inputBorderError {
  border-bottom: 1px solid var(--color-error);
  color: var(--color-error);
}

.input {
  font-family: var(--font-family);
  flex: 1;
  border: 0;
  outline: 0;
  background-color: transparent;
  margin: 10px 0 5px -1px;
  z-index: 5;
  width: 100%;
  font-size: 16px;
  line-height: 25px;
  color: var(--color-content-1);
  caret-color: var(--color-content-1); /* Shows blinking cursor */
}

.startIcon {
  margin-inline-end: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  position: absolute;
  z-index: 1;
  margin-top: -12px;
  font-size: 14px;
  font-weight: 500;
  align-self: start;

  transition: all 300ms;
}

.bigLowerLabel {
  font-size: 16px;
  margin-top: 11px;
}

.bottomText {
  margin-top: 3px;
  font-size: 0.8rem;
  color: var(--color-content-3);
}

.error {
  color: var(--color-error);
}

.eyeIcon {
  margin-bottom: 5px;
  stroke: var(--color-content-3);
}

.storyIcon {
  fill: var(--color-content-3);
}

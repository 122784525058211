.splashContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.splashContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.logo {
  width: 80px;
}

.spinner {
  padding-top: 20px;
}

.versionNumber {
  text-align: center;
  color: var(--color-content-1);
  font-size: 12px;
  line-height: 24px;
}

.error {
  position: absolute;
  top: 80%;
  color: var(--color-error);
  font-size: 16px;
  text-align: center;
  padding: 0 10px;
}

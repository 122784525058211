.dividerContainer {
  padding: 10px 0;
}

.divider {
  border-bottom: 1px solid var(--color-content-3);
  position: relative;
}

.textContainer {
  position: absolute;
  text-align: center;
  top: -1px;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 80%;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-content-3);
  background-color: var(--color-background);
  border-left: 10px solid var(--color-background);
  border-right: 10px solid var(--color-background);
}

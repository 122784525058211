.header {
  position: relative;
  height: 50px;
  display: grid;
  grid-template-columns: 50px auto 50px;
  grid-template-rows: 50px;
  justify-items: stretch;
  grid-gap: 10px;
  align-items: center;
  padding-top: max(env(safe-area-inset-top), 10px);
  padding-bottom: 10px;
}

.titleContainer {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: var(--color-content-1);
  text-align: center;
  align-self: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.button {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.headerButton {
  text-align: center;
}

.option {
  font-size: 16px;
  line-height: 22px;
  color: var(--color-content-1);
  white-space: nowrap;
  padding: 0 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.iconAndText {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  margin-inline-end: 10px;
}

.iconFill {
  fill: var(--color-content-1);
}

.iconStroke {
  stroke: var(--color-content-1);
}

//.option:hover {
//  background-color: var(--color-content-4);
//}
.option:active {
  background-color: var(--color-content-5);
}

.extraPadding {
  padding: 0 40px;
}

.noPadding {
  padding: 0;
}

.selectedV {
  margin-inline-start: 15px;
}

.selectedText {
  font-weight: 600;
}

.V {
  stroke: var(--color-success-dark);
}

.radioCircle {
  height: 20px;
  width: 20px;
  border-radius: 24px;
  border: 2px solid var(--color-content-2);
}

.radioCircleSelected {
  height: 10px;
  width: 10px;
  border-radius: 24px;
  border: 7px solid var(--color-secondary);
  background-color: var(--color-white);
}

.checkbox {
  height: 20px;
  width: 20px;
  border: 2px solid var(--color-content-3);
}

.checkboxSelected {
  height: 24px;
  width: 24px;
  background-color: var(--color-secondary);
}

.languageScreenContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.languagesList {
  padding: 10px 0;
  overflow-y: auto;
  flex: 1;
}

.addTranslation {
  padding: 20px 30px 0 30px;
  text-align: center;
  color: var(--color-content-1);
  font-size: 14px;
  line-height: 22px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 10px -1px 0 0 rgba(0, 0, 0, 0.05);
  margin-bottom: -10px;
}

.addTranslationButton {
  margin-top: 10px;
}

.subtitle {
  margin: 10px 5px;
  color: var(--color-content-2);
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.otherInput {
  margin-top: -20px;
  padding: 0 40px;
}

.divider {
  padding: 10px 20px;
}

.saveButton {
  margin: 20px;
}

.methodsPicker {
  margin: 20px 40px;
}

.notificationMethod {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}

.methodTitle {
  flex: 1;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-content-1);
}

.appName {
  font-family: Urbanist, var(--font-family);
  text-align: center;
  color: var(--color-primary-dark);
  font-size: 26px;
  line-height: 22px;
  font-weight: 600;
  direction: ltr;

  margin-bottom: 20px;
}

.appNamePeriod {
  color: var(--color-secondary-dark);
  margin-inline-start: 1px;
  font-size: 48px;
}

.resetPassword {
  width: 100%;
  text-align: end;
  margin-top: -15px;
  margin-bottom: 20px;
  font-size: 12px;
}

.icon {
  stroke: var(--color-content-3);
}

.lockIcon {
  margin-bottom: 4px;
  fill: var(--color-content-3);
}

.divider {
  margin-top: 60px;
  margin-bottom: 20px;
}

.logoContainer {
  text-align: center;
  margin-top: 40px;
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  flex: 1;
  height: 49px;
  min-height: 49px;
  border-radius: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-black);
  margin-bottom: 10px;
  max-width: 350px;
  padding: 0 20px;
}

.primary {
  background-color: var(--color-primary);
  color: var(--color-extreme);
}
/*.primary:hover {*/
/*  background-color: var(--color-primary-light);*/
/*}*/
.primary:active {
  background-color: var(--color-primary-dark);
}

.secondary {
  background-color: var(--color-secondary);
}
/*.secondary:hover {*/
/*  background-color: var(--color-secondary-light);*/
/*}*/
.secondary:active {
  background-color: var(--color-secondary-dark);
}

.info {
  background-color: var(--color-button-1);
}
/*.info:hover {*/
/*  background-color: var(--color-button-2);*/
/*}*/
.info:active {
  background-color: var(--color-button-3);
}

.clickable {
  cursor: pointer;
}

.disabled {
  background-color: var(--color-disabled) !important;
  color: var(--color-disabled-text) !important;
}

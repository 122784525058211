.searchBarContainer {
  background-color: var(--color-content-5);
  border-radius: 20px;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: text;
}

.placeholder {
  font-size: 16px;
  line-height: 22px;
  color: var(--color-content-3);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchIcon {
  margin-inline-start: 20px;
  margin-inline-end: 10px;
  fill: var(--color-content-3);
  margin-bottom: 2px;
}

.searchInput {
  font-family: var(--font-family);
  border: 0;
  outline: 0;
  background-color: transparent;
  margin-inline-start: 15px;
  z-index: 10;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-content-2);
  caret-color: var(--color-content-2); /* Shows blinking cursor */
}

.clearButtonContainer {
  background-color: var(--color-content-3);
  margin-inline-end: 8px;
}

.clearX {
  background-color: var(--color-black);
}

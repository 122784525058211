.screenContainer {
  display: flex;
  flex-direction: column;
}

.logo {
  height: 43px;
}

.inputFields {
  margin: 0 10px;
}

.actionButton {
  margin-top: 10px;
}

.alternativeText {
  font-size: 14px;
  text-align: center;
  color: var(--color-content-2);
}

.alternativeButton {
  font-size: 14px;
  font-weight: 500;
}

.divider {
  margin-top: 20px;
  margin-bottom: 10px;
}

.flex1 {
  flex: 1;
}

.container {
  height: 60px;
  width: 60px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bigIcon {
  margin: 20px;
  transform: scale(1.3);
}

.iconCircle {
  height: 100%;
  width: 100%;
  border-radius: 60px;
  background-color: var(--color-cards);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.google {
  background-color: var(--color-google);
}
.google:active {
  background-color: var(--color-google-active);
}

.facebook {
  background-color: var(--color-facebook);
}
.facebook:active {
  background-color: var(--color-facebook-active);
}

.apple {
  background-color: var(--color-apple);
}
.apple:active {
  background-color: var(--color-apple-active);
}

.appleIcon {
  margin-bottom: 4px;
}

.buttonsRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-bottom: 10px;
}

.error {
  font-size: 16px;
  color: var(--color-error);
  text-align: center;
  margin: 0 20px;
}

.waitingCircle {
  background-color: var(--color-disabled);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

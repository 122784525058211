.clearButtonContainer {
  height: 24px;
  width: 24px;
  border-radius: 100px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.clearButton {
  position: absolute;
  height: 13px;
  width: 2px;
  transform: rotate(45deg);
  z-index: 20;
  background-color: var(--color-content-2);
}

.clearButton2 {
  transform: rotate(-45deg);
}

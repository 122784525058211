.toggleContainer {
  width: 51px;
  height: 28px;
  position: relative;
  background-color: var(--color-content-4);
  border-radius: 20px;
  cursor: pointer;

  transition: background-color 100ms ease-out;
}

.toggleContainerEnabled {
  background-color: var(--color-success);
}

.toggleCircle {
  position: absolute;
  top: 3px;
  left: 4px;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  background-color: var(--color-white);
  box-shadow:
    0 2px 7px rgba(0, 0, 0, 0.15),
    0 2px 1px rgba(0, 0, 0, 0.05);

  transition:
    left 100ms ease-out,
    right 100ms ease-out;
}

[dir="rtl"] .toggleCircle {
  left: unset;
  right: 4px;
}

.toggleEnabled {
  left: 25px; /* 51 - 4 - 22*/
}

[dir="rtl"] .toggleEnabled {
  left: unset;
  right: 25px;
}

.modalBackground {
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  z-index: 60;
  width: 80vw;
  background-color: var(--color-content);
  padding: 0 15px 15px 15px;
  box-shadow:
    0 0 10px rgba(0, 0, 0, 0.1),
    0 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.modalContent {
  padding-top: 15px;
  width: 100%;
  position: relative;
}

.closeButton {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 70;
  width: 100%;
}

.illustration {
  text-align: center;
}

.title {
  font-size: 18px;
  font-weight: 500;
  color: var(--color-content-1);
  text-align: center;
  margin: 10px;
}

.content {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-content-3);
  text-align: center;
  margin: 20px 10px;
}

.children {
  margin: 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.button {
  margin: 5px;
  flex: 1;
  height: 40px;
  max-width: 120px;
  white-space: nowrap;
}

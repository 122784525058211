.title {
  font-size: 16px;
  line-height: 22px;
  color: var(--color-content-1);
  text-align: center;
  padding: 20px 10px 30px 10px;
  width: 80%;
  margin: 0 auto;
}

.illustration {
  text-align: center;
  padding-top: 20px;
}

.popupText {
  font-size: 16px;
  color: var(--color-content-3);
  text-align: center;
}

.popupIllustration {
  padding-top: 15px;
}

.email {
  color: var(--color-content-2);
  text-align: center;
  font-size: 14px;
  padding: 20px;
}

.emailAddress {
  white-space: nowrap;
}

.container {
  width: 100%;
  height: 10px;
  position: relative;
  margin: 5px 0;
}

.common {
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  border-radius: 40px;
}

[dir="rtl"] .common {
  left: unset;
  right: 0;
}

.barBackground {
  width: 100%;
  background-color: var(--progress-bar-background);
}

.aboutToExpire {
  background-color: var(--progress-bar-about-to-expire);
}

.expireSoon {
  background-color: var(--progress-bar-soon);
}

.expiresToday {
  background-color: var(--progress-bar-background);
}

.expired {
  background-color: var(--progress-bar-expired);
}

.normal {
  background-color: var(--progress-bar-normal);
}

.storyText {
  color: var(--color-content-1);
}

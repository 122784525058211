.fieldContent {
  z-index: 5;
  width: 100%;
  border: 0;
  outline: 0;
  cursor: pointer;

  font-size: 16px;
  color: var(--color-content-1);
  line-height: 25px;
  height: 25px;

  display: flex;
  flex-direction: column-reverse;
  margin: 10px 0 5px 0;
}

.chevron {
  stroke: var(--color-content-2);
}

.modalBackground {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.modal {
  z-index: 20;
  position: fixed;
  bottom: 0;
  left: 0;
  max-height: 80vh;
  width: 100vw;
  background-color: var(--color-content);
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  transition: height 300ms;
}

.modalHeader {
  margin: 0 20px;
  color: var(--color-content-1);
}

.divider {
  border-bottom: 1px solid var(--color-content-5);
}

.optionsList {
  overflow-y: auto;
}

.button {
  display: inline-block;
  margin-bottom: 10px;
}

.primary {
  color: var(--color-primary);
}
/*.primary:hover {*/
/*  color: var(--color-primary-light);*/
/*}*/
.primary:active {
  color: var(--color-primary-dark);
}

.secondary {
  color: var(--color-secondary);
}
/*.secondary:hover {*/
/*  color: var(--color-secondary-light);*/
/*}*/
.secondary:active {
  color: var(--color-secondary-dark);
}

.info {
  color: var(--color-content-4);
}
/*.info:hover {*/
/*  color: var(--color-content-5);*/
/*}*/
.info:active {
  color: var(--color-content-3);
}

.clickable {
  cursor: pointer;
}

.story {
  color: var(--color-content-2);
  font-size: 14px;
  font-weight: 500;
}

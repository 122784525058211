.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 0;
  background-color: transparent;
  opacity: 50%;

  z-index: 1000;
  transition: background-color 0.5s;
}

.backgroundOpen {
  background-color: var(--color-black);
  width: 100vw;
}

.drawer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80vw;
  @media (min-width: 760px) {
    width: 400px;
  }
  background-color: var(--color-background);
  z-index: 2000;
  box-shadow:
    0px 4px 10px rgba(0, 0, 0, 0.19),
    0px 4px 30px rgba(0, 0, 0, 0.25);
  overflow-y: auto;

  transform: translateX(-100vw);
  transition: transform 0.5s;
}

[dir="rtl"] .drawer {
  left: unset;
  right: 0;
  transform: translateX(100vw);
}

.drawerOpen {
  transform: none;
}

[dir="rtl"] .drawerOpen {
  transform: none;
}

.content {
  direction: ltr;
}

.packageName {
  color: var(--color-content-1);
  font-size: 16px;
}

.license {
  white-space: break-spaces;
  color: var(--color-content-2);
  font-size: 12px;
}

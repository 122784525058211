.fabContainer {
  width: 56px;
  height: 56px;
  border-radius: 100px;
  background-color: var(--color-secondary);
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

//.fabContainer:hover {
//  background-color: var(--color-secondary-light);
//}

.fabContainer:active {
  background-color: var(--color-secondary-dark);
}

.iconColorProd {
  fill: var(--color-fab-icon);
}

.iconColorDev {
  fill: var(--color-error);
}

.fixedContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0 25px 25px 0;
}

[dir="rtl"] .fixedContainer {
  right: unset;
  left: 0;
  padding: 0 0 25px 25px;
}

.imageContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.image {
  display: inline-block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.overlay {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-content-5);
  justify-content: center;
  align-items: center;
}

.spinner {
  color: white !important;
}

.missingImage {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--color-content-5);
  fill: var(--color-content-4);
}

.missingImageIcon {
  font-size: 30px;
  max-width: 60%;
}

.options {
  flex: 1;
  height: 100%;
}

.noOptions {
  padding: 30px;
  text-align: center;
  color: var(--color-content-2);
  font-size: 16px;
  line-height: 22px;
}

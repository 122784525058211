.imageFieldInput {
  z-index: 5;
  width: 100%;
  border: 0;
  outline: 0;
  cursor: pointer;

  font-size: 16px;
  color: var(--color-content-1);
  line-height: 25px;
  height: 25px;

  display: flex;
  flex-direction: column-reverse;
  margin: 10px 0 5px 0;
}

.iconRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60px;
}

.onlineImageSelector {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
  height: 100vh;
  width: 100vw;
}

.transformerWrapper {
  height: 100%;
  width: 100%;
  flex: 1;
}

.image {
  border: 1px solid var(--color-content-5);
  border-radius: 10px;
  /* min-width: 70vw; */
  /* min-height: 30vh; */
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: var(--color-content-1);
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0;

  @media (max-width: 360px) {
    font-size: 16px;
  }
}

[dir="rtl"] .icon {
  transform: scaleX(-1);
}

.enabled {
  cursor: pointer;
}

.notSelectedFill {
  fill: var(--color-content-1);
}

.selectedFill {
  fill: var(--color-primary);
  color: var(--color-primary);
}

.disabledFill {
  fill: var(--color-content-4);
  color: var(--color-content-4);
}

.notSelectedStroke {
  stroke: var(--color-content-1);
}

.selectedStroke {
  stroke: var(--color-primary);
  color: var(--color-primary);
}

.disabledStroke {
  stroke: var(--color-content-4);
  color: var(--color-content-4);
}

.title {
  margin-inline-start: 10px;
}

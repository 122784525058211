.itemsList {
  display: flex;
  flex-direction: column;

  @media (min-width: 760px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.topButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-inline-end: 10px;
}

.noItems {
  padding: 20px;
  color: var(--color-content-3);
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}

.content {
  text-align: center;
}

.expiredItemsLabel {
  text-align: center;
  color: var(--color-content-2);
  font-size: 16px;
  line-height: 22px;
  margin: 10px 0;
  width: 100%;
}

.illustration {
  width: 100%;
  background-color: var(--color-content-4);
  height: 300px;
  margin-bottom: 20px;
}

.content {
  text-align: center;
  height: 100%;
}

.noItems {
  color: var(--color-content-3);
  font-size: 16px;
  line-height: 28px;
  text-align: center;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.illustration {
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
}

.addItemArrow {
  margin-inline-start: 20px;
  margin-bottom: 30px;
}

.searchBar {
  margin-bottom: 10px;
}

.searchNoItems {
  color: var(--color-content-2);
  padding: 50px 20px;
}

.noItemsIllustration {
  margin-bottom: 40px;
}

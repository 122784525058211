.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.welcomeText {
  text-align: center;
  color: var(--color-content-1);
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
  margin: 50px 10px 20px 10px;
}

.welcomeTextDev {
  color: var(--color-error);
}

.subtitle {
  color: var(--color-content-2);
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  margin: 0 30px;
}

.illustration {
  text-align: center;
  width: 100%;
  margin: 40px;
}

.buttonsContainer {
  text-align: center;
  width: 200px;
}

.button {
  margin-bottom: 10px;
}

.signInWith {
  color: var(--color-content-1);
  font-size: 16px;
}

.divider {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;
}

.title {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-content-1);
  margin: 20px 20px;
}

.icon {
  stroke: var(--color-content-3);
}

.lockIcon {
  fill: var(--color-content-3);
}

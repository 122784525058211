.container {
  height: 100%;
}

.buttonsContainer {
  height: 47px;
  width: 100%;
}

.buttons {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

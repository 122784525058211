.title {
  text-align: center;
  color: var(--color-content-1);
  font-size: 20px;
  font-weight: 500;
  padding: 20px;
}

.searchBar {
  margin-bottom: 5px;
}

.addCategoryButton {
  font-size: 16px;
  margin-bottom: 20px;
}

.addCategoryInput {
  margin-top: 35px;
}

.categoryInputField {
  height: 25px;
  margin-left: 0 !important; // Removes the margin in input
  cursor: pointer;
}

.icon {
  stroke: var(--color-primary);
}

[dir="rtl"] .icon {
  transform: scaleX(-1);
}

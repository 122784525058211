.subtitle {
  font-size: 14px;
  line-height: 22px;
  color: var(--color-content-2);
  margin: 20px;
}

.image {
  text-align: center;
}

.content {
  margin-top: 0;
}

.successScreenContainer {
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.successTitle {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: var(--color-content-1);
  margin: 30px;
}

.successSubtitle {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-content-2);
  width: 70%;
}

.successText {
  text-align: center;
  color: var(--color-content-1);
  font-size: 16px;
}

.continueButton {
  min-width: 75%;
  margin: 25px;
}

.icon {
  stroke: var(--color-content-3);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.transformerWrapper {
  height: 100%;
  width: 100%;
  flex: 1;
}

.image {
  border: 1px solid var(--color-content-5);
  border-radius: 10px;
  min-width: 70vw;
  min-height: 30vh;
}

.stepper {
  width: 80%;
}

.error {
  font-size: 14px;
  color: var(--color-error);
  text-align: center;
  margin-bottom: 5px;
}

.actionButtons {
  padding: 20px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
  background-color: var(--color-receipt-buttons);
}

.actionButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.activeActionButton {
  color: var(--color-content-1);
  cursor: pointer;
}

.disabledActionButton {
  color: var(--color-content-4);
}

.icon {
  height: 32px;
}

.downloadPdfIllustration {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imageList {
  margin: 20px 0;

  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 20px;
  row-gap: 20px;
}

.singleImage {
  aspect-ratio: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image {
  border-radius: 10px;
}

.image:active {
  opacity: 0.5;
  width: 96%;
  height: 96%;
  margin: 2%;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}
.button {
  padding: 0 40px;
  min-width: 80%;
}

.loading {
  text-align: center;
  padding: 40px;
}

.firstSearch {
  margin-top: 40px;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-content-1);
}

.illustration {
  margin-top: 40px;
}

.noImages {
  margin: 60px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-content-2);
  text-align: center;
}

.noImagesTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: var(--color-content-1);
  margin-bottom: 10px;
}

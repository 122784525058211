.itemScreen {
  display: flex;
  flex-direction: column;
  @media (min-width: 760px) {
    flex-direction: row;
    justify-content: center;
  }
}

.imageContainer {
  width: 100%;
  height: 60vw;
  margin-bottom: 15px;

  @media (min-width: 760px) {
    width: 450px;
    height: 450px;
    padding-inline-end: 40px;
  }
}

.image {
  border: 1px solid var(--color-content-5);
  box-sizing: border-box;
  border-radius: 10px;
}

.editButton {
  margin-inline-end: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  @media (min-width: 760px) {
    width: 450px;
  }
}

.itemProgressBar {
  font-size: 14px;
  line-height: 22px;
  color: var(--color-content-1);
}

.fields {
  flex: 1;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
}

.itemField {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: var(--color-content-1);
  margin-top: 25px;
  white-space: break-spaces;
}

.itemFieldTitle {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-content-3);
}

.actionBox {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.actionButton {
  width: 100%;
}

.receiptUploadError {
  color: var(--color-error);
  text-align: center;
}

.deleteButton {
  transform: scale(0.8);
}
